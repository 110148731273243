import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
    providedIn: 'root'
})
export class ComunityService {
    private menu = new BehaviorSubject('');
    private subMenu = new BehaviorSubject('');
    private icon = new BehaviorSubject('');
    private optMenu = new BehaviorSubject('');
    private portHub = new BehaviorSubject('');

    menu$ = this.menu.asObservable();
    subMenu$ = this.subMenu.asObservable();
    icon$ = this.icon.asObservable();
    optMenu$=this.optMenu.asObservable();
    portHub$=this.portHub.asObservable();


    private dataOutlet = new BehaviorSubject('');

    dataOutlet$ = this.dataOutlet.asObservable();

    changeDataOutlet(data: any) {
      this.dataOutlet.next(data)
    }

    viewPortHub(portHub:any){
        this.portHub.next(portHub);
    }

    changeData(menu: string, subMenu: string) {
        this.menu.next(menu);
        this.subMenu.next(subMenu);
    }

    changeIcon(icon:string){
        this.icon.next(icon);
    }

    changeOptMenu(optMenu:string){
        this.optMenu.next(optMenu);
    }


}